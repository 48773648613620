import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setAppApolloClientContextLink } from './ApolloClient';
import Bugsnag from '@bugsnag/js';
import { OperationDefinitionNode } from 'graphql';

const isSSR = typeof window === 'undefined';

const createErrorLink = (): ApolloLink => {
  return onError(err => {
    // TODO: move this to a common function since this code is repeated in client side ApolloClient
    const { graphQLErrors, networkError, operation } = err;
    Bugsnag.addMetadata('GraphQL', { operation });
    if (graphQLErrors) {
      let unauthError = false;
      graphQLErrors.forEach(err => {
        const { message: errMsg, path } = err;
        switch (err.extensions?.code) {
          case 'UNAUTHENTICATED':
            unauthError = true;
            break;
          default: {
            Bugsnag.notify(
              `[GraphQL error]: Message: ${errMsg}, error: \
                  ${JSON.stringify(err)}, Path: ${path}`
            );
            const hasMutation = operation.query.definitions.some(
              d => (d as OperationDefinitionNode).operation === 'mutation'
            );
            if (hasMutation && !isSSR) {
              const lastSawErr = localStorage.getItem('lastSawError');
              // prevent error message spam when multiple queries fail
              if (
                !lastSawErr || parseInt(lastSawErr) + 10000 < Date.now()
              ) {
                localStorage.setItem('lastSawError', Date.now().toString());
                // TODO: notify slack with a hotjar link
              }
            }
            break;
          }
        }
      });

      if (unauthError) {
        return;
      }
    }

    console.error(
      operation,
      graphQLErrors,
      networkError,
      JSON.stringify(graphQLErrors),
    );

    if (networkError) {
      const isUnauthenticatedError = networkError.stack &&
        /Received status code 401/.test(networkError.stack);
      // The 401 response for an expired session will trigger this
      // codepath, but we can ignore it because reauth is handled deeper in
      // app
      if (
        operation.operationName === 'V2Gateway_checkSessionValid' ||
        operation.operationName === 'V2Gateway_GetIdentity' ||
        isUnauthenticatedError
      ) {
        return;
      }
      Bugsnag.notify(`[GraphQL Network error]: ${networkError}`);
    }
  });
};

export const initApolloServerSideClient =
  (): ApolloClient<NormalizedCacheObject> => {
    const httpLink = new HttpLink({
      uri: process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
      credentials: 'include',
    });

    const link = createErrorLink()
      .concat(setAppApolloClientContextLink)
      .concat(httpLink);

    const cache = new InMemoryCache({
      resultCaching: false,
    });

    const client = new ApolloClient({
      cache,
      link,
      ssrMode: true,
    });

    return client;
  };

export class AppServerSideApolloClient {
  private static client: ApolloClient<object> | null = null;

  static getClient(): ApolloClient<object> {
    if (this.client === null) {
      this.client = initApolloServerSideClient();
    }
    return this.client;
  }
}

