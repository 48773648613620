import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  defaultDataIdFromObject,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import APIOperationMap, { AppOperationType } from '@/src/js/APIOperationMap';

const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY;
const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;
const BUGSNAG_RELEASE_STAGE = process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE;
const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;

const isSSR = typeof window === 'undefined';

export const createApolloErrorLink = (): ApolloLink => {
  return onError((err) => {
    const { graphQLErrors, networkError, operation } = err;
    Bugsnag.addMetadata('GraphQL', { operation });
    if (graphQLErrors) {
      let unauthError = false;
      graphQLErrors.forEach(err => {
        const { message: errMsg, path } = err;
        switch (err.extensions?.code) {
          case 'UNAUTHENTICATED':
            unauthError = true;
            break;
          default: {
            const msg = `[GraphQL error]: Message: ${errMsg}, error: \
                  ${JSON.stringify(err)}, Path: ${path}`;
            console.error(msg);
            Bugsnag.notify(msg);
            break;
          }
        }
      });

      if (unauthError) {
        return;
      }
    }

    console.error('err', err);

    if (networkError) {
      const isUnauthenticatedError = networkError.stack &&
        /Received status code 401/.test(networkError.stack);
      // The 401 response for an expired session will trigger this
      // codepath, but we can ignore it because reauth is handled deeper in
      // app
      if (
        operation.operationName === 'V2Gateway_checkSessionValid' ||
        operation.operationName === 'V2Gateway_GetIdentity' ||
        isUnauthenticatedError
      ) {
        return;
      }
      const msg = `[GraphQL Network error]: ${networkError}`;
      Bugsnag.notify(msg);
      console.error(msg);
    }

    console.error(
      operation,
      graphQLErrors,
      networkError,
      JSON.stringify(graphQLErrors),
    );
  });
};

export const setAppApolloClientContextLink = setContext((request, context) => {
  const operationPrefix = request.operationName?.split('_')[0];
  const reRoute = operationPrefix ?
    APIOperationMap[operationPrefix as AppOperationType] :
    APIOperationMap[request.operationName as AppOperationType];
  if (reRoute) {
    context.uri = reRoute;
  }
  return context;
});

export const initApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  if (NODE_ENV === 'production' && BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact],
      appVersion: APP_VERSION,
      enabledReleaseStages: ['production', 'staging'],
      releaseStage: BUGSNAG_RELEASE_STAGE,
    });
  }

  const retryLink = new RetryLink({
    attempts: {
      // Don't retry expired session ops
      retryIf: (
        unusedError,
        { operationName },
      ) => operationName !== 'V2Gateway_checkSessionValid' &&
      operationName !== 'V2Gateway_GetIdentity' &&
        operationName !== 'V2Gateway_Login'
    }
  });

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
    credentials: 'include',
  });

  const uploadLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
    credentials: 'include',
  });

  const link = createApolloErrorLink()
    .concat(setAppApolloClientContextLink)
    .concat(retryLink.split(
      op => op.getContext().uri === process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
      uploadLink,
      httpLink,
    ));

  const cache = new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'Location': return `L:${responseObject.uid}:${responseObject.id}`;
        case 'ServiceArea': return `SA:${responseObject.uid}`;
        case 'LocationsQueryResult': return `LQR:${(responseObject.locations as any)?.map((l: any) => l.__ref).join(',')}`;
        case 'BookingQueryResult': return `BQR:${(responseObject.bookings as any)?.map((l: any) => l.uid).join(',')}`;
        case 'ServiceProvider': return `SP:${responseObject.ownerUid}`;
        default: return defaultDataIdFromObject(responseObject);
      }
    }
  });

  const client = new ApolloClient({
    cache,
    link,
    ssrMode: isSSR,
  });

  return client;
};

